<script>
import { Line, mixins } from 'vue-chartjs';
import { chartTooltipStyles } from '@/core/setting';

const { reactiveProp } = mixins;

const fontFamily = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"';

const fontStyle = {
  fontSize: 12,
  fontColor: '#212529',
  fontFamily,
};

export default {
  name: 'NutritionCaloriesGraph',
  mixins: [Line, reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      config: {
        xAxesTicks: {
          ...fontStyle,
        },
        yAxesTicks: {
          ...fontStyle,
        },
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      tooltips: {
        ...chartTooltipStyles,
        mode: 'index',
        intersect: false,
        callbacks: {
          title: (tooltipItems) => {
            const { xLabel } = tooltipItems[0];
            return xLabel.join(' - ');
          },
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              ...this.config.xAxesTicks,
              display: true,
              maxRotation: 0,
              minRotation: 90,
              autoSkip: false,
              callback: (val, index) => {
                const lbl = val[0].replace(',', '').trim();
                let mod = 1;
                const { labels } = this.chartData;
                if (labels.length >= 90) {
                  mod = 6;
                } else if (labels.length >= 60) {
                  mod = 4;
                }
                return ((index + 1) % mod === 0 || index === 0 ? lbl : '');
              },
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              ...this.config.yAxesTicks,
              display: true,
            },
          },
        ],
      },
    });
  },
};
</script>
